var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',[(_vm.$apollo.loading || _vm.loading)?_c('div',[_c('p',[_vm._v("Cargando...")])]):_c('section',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/2 w-full mb-6"},[_c('label',{staticClass:"text-sm text-san-juan font-bold"},[_vm._v("Fecha Inicio")]),_c('ValidationProvider',{attrs:{"name":"Fecha Inicio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datepicker',{attrs:{"disabled":_vm.invoiceWasCalculated,"label":"Fecha Inicio","language":_vm.langEs,"name":"start-date","disabledDates":_vm.disabledDatesFrom},model:{value:(_vm.invoice.startDate),callback:function ($$v) {_vm.$set(_vm.invoice, "startDate", $$v)},expression:"invoice.startDate"}}),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full mb-6"},[_c('label',{staticClass:"text-sm text-san-juan font-bold"},[_vm._v("Fecha Fin")]),_c('ValidationProvider',{attrs:{"name":"Fecha Fin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datepicker',{attrs:{"disabled":_vm.invoiceWasCalculated,"label":"Fecha Fin","language":_vm.langEs,"name":"start-date","disabledDates":_vm.disabledDatesTo},model:{value:(_vm.invoice.endDate),callback:function ($$v) {_vm.$set(_vm.invoice, "endDate", $$v)},expression:"invoice.endDate"}}),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full mb-6"},[_c('ValidationProvider',{attrs:{"name":"Remitentes","vid":"Remitentes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.loadingContacts)?_c('vs-select',{staticClass:"w-full",attrs:{"label":"Remitentes","placeholder":"Remitentes","autocomplete":"","multiple":"","disabled":_vm.invoiceWasCalculated},model:{value:(_vm.invoice.senders),callback:function ($$v) {_vm.$set(_vm.invoice, "senders", $$v)},expression:"invoice.senders"}},_vm._l((_vm.allContacts),function(sender,index){return _c('div',{key:index,staticClass:"items-select",attrs:{"value":sender.id}},[_c('vs-select-item',{attrs:{"value":sender.id,"text":("" + (sender.name))}})],1)}),0):_c('div',{staticClass:"py-4"},[_vm._v("...")]),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full mb-6"},[_c('label',{staticClass:"text-sm text-san-juan font-bold"},[_vm._v("Observación")]),_c('ValidationProvider',{attrs:{"rules":"required|max:300","name":"Observación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{attrs:{"placeholder":"Observación"},model:{value:(_vm.invoice.observation),callback:function ($$v) {_vm.$set(_vm.invoice, "observation", $$v)},expression:"invoice.observation"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{},[_c('h1',{staticClass:"text-xl mb-4"},[_vm._v("Calcular factura")]),_c('p',{staticClass:"pb-5"},[_vm._v(" Calcula el valor de la factura para las fechas y remitentes seleccionados. Debes calcular la factura antes de guardarla. ")]),_c('div',{staticClass:"flex pb-5 justify-between flex-wrap w-64"},[_c('span',[_c('p',{staticClass:"font-bold text-slate-gray"},[_vm._v("Valor a facturar:")])]),_c('span',[_c('p',{staticClass:"text-san-juan font-bold"},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(_vm.calculatedInvoice))+" ")])])]),_c('div',{staticClass:"flex gap-3"},[_c('vs-button',{staticClass:"mr-3 mb-2",attrs:{"color":"success"},on:{"click":_vm.calculateInvoice}},[_vm._v(" Calcular factura ")]),_c('vs-button',{staticClass:"mr-3 mb-2",attrs:{"type":"border"},on:{"click":_vm.cleanCalculateInvoice}},[_vm._v(" Limpiar ")])],1)]),_c('div',{staticClass:"mt-20"},[_c('div',{staticClass:"vx-col w-full mb-6"},[_c('div',{staticClass:"flex flex-wrap justify-end"},[_c('vs-button',{staticClass:"mr-3 mb-2",attrs:{"disabled":!_vm.invoiceWasCalculated},on:{"click":_vm.createInvoice}},[_vm._v("Guardar")]),_c('vs-button',{staticClass:"mb-2",attrs:{"color":"primary","type":"border"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Descartar ")])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }