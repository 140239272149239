<template>
  <vx-card>
    <div v-if="$apollo.loading || loading">
      <p>Cargando...</p>
    </div>
    <section v-else>
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mb-6">
          <label class="text-sm text-san-juan font-bold">Fecha Inicio</label>
          <ValidationProvider
            name="Fecha Inicio"
            rules="required"
            v-slot="{ errors }"
          >
            <datepicker
              :disabled="invoiceWasCalculated"
              label="Fecha Inicio"
              :language="langEs"
              name="start-date"
              v-model="invoice.startDate"
              :disabledDates="disabledDatesFrom"
            ></datepicker>
            <div class="text-danger text-sm leading-tight">
              {{ errors[0] }}
            </div>
          </ValidationProvider>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-6">
          <label class="text-sm text-san-juan font-bold">Fecha Fin</label>
          <ValidationProvider
            name="Fecha Fin"
            rules="required"
            v-slot="{ errors }"
          >
            <datepicker
              :disabled="invoiceWasCalculated"
              label="Fecha Fin"
              :language="langEs"
              name="start-date"
              v-model="invoice.endDate"
              :disabledDates="disabledDatesTo"
            ></datepicker>
            <div class="text-danger text-sm leading-tight">
              {{ errors[0] }}
            </div>
          </ValidationProvider>
        </div>

        <div class="vx-col md:w-1/2 w-full mb-6">
          <ValidationProvider
            name="Remitentes"
            vid="Remitentes"
            v-slot="{ errors }"
            rules="required"
          >
            <vs-select
              v-if="!loadingContacts"
              label="Remitentes"
              v-model="invoice.senders"
              class="w-full"
              placeholder="Remitentes"
              autocomplete
              multiple
              :disabled="invoiceWasCalculated"
            >
              <div
                class="items-select"
                v-for="(sender, index) in allContacts"
                :value="sender.id"
                :key="index"
              >
                <vs-select-item :value="sender.id" :text="`${sender.name}`" />
              </div>
            </vs-select>
            <div v-else class="py-4">...</div>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-6">
          <label class="text-sm text-san-juan font-bold">Observación</label>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required|max:300"
            name="Observación"
          >
            <vs-textarea
              v-model="invoice.observation"
              placeholder="Observación"
            />
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="">
        <h1 class="text-xl mb-4">Calcular factura</h1>
        <p class="pb-5">
          Calcula el valor de la factura para las fechas y remitentes
          seleccionados. Debes calcular la factura antes de guardarla.
        </p>

        <div class="flex pb-5 justify-between flex-wrap w-64">
          <span>
            <p class="font-bold text-slate-gray">Valor a facturar:</p>
          </span>
          <span>
            <p class="text-san-juan font-bold">
              {{ calculatedInvoice | currencyFormat }}
            </p>
          </span>
        </div>
        <div class="flex gap-3">
          <vs-button
            color="success"
            class="mr-3 mb-2"
            @click="calculateInvoice"
          >
            Calcular factura
          </vs-button>
          <vs-button
            class="mr-3 mb-2"
            @click="cleanCalculateInvoice"
            type="border"
          >
            Limpiar
          </vs-button>
        </div>
      </div>
      <div class="mt-20">
        <div class="vx-col w-full mb-6">
          <div class="flex flex-wrap justify-end">
            <vs-button
              :disabled="!invoiceWasCalculated"
              class="mr-3 mb-2"
              @click="createInvoice"
              >Guardar</vs-button
            >
            <vs-button
              @click="$router.go(-1)"
              color="primary"
              type="border"
              class="mb-2"
            >
              Descartar
            </vs-button>
          </div>
        </div>
      </div>
    </section>
  </vx-card>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ValidationProvider } from 'vee-validate';
import { es } from 'vuejs-datepicker/src/locale';
import moment from 'moment';

import { CONTACTS_IN_DATES, CALCULATE_INVOICE } from '@/graphql/queries.js';
import { CREATE_INVOICE } from '@/graphql/mutations';

export default {
  components: {
    Datepicker,
    ValidationProvider,
  },
  data() {
    return {
      langEs: es,
      invoice: {
        observation: '',
        senders: [],
        startDate: new Date(),
        endDate: new Date(),
      },
      allContacts: [],
      invoiceWasCalculated: false,
      calculatedInvoice: 0,
      loading: false,
      loadingContacts: true,
    };
  },
  apollo: {
    allContactsInDates: {
      query: CONTACTS_IN_DATES,
      variables: {
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      },
      result({ data }) {
        this.allContacts = data?.allContactsInDates ?? [];
        this.loadingContacts = false;
      },
    },
  },
  watch: {
    'invoice.startDate'() {
      this.getAllContacts();
    },
    'invoice.endDate'() {
      this.getAllContacts();
    },
  },
  methods: {
    async getAllContacts() {
      try {
        this.loadingContacts = true;
        const result = await this.$apollo.query({
          query: CONTACTS_IN_DATES,
          fetchPolicy: 'network-only',
          variables: {
            startDate: moment(this.invoice.startDate).format('YYYY-MM-DD'),
            endDate: moment(this.invoice.endDate).format('YYYY-MM-DD'),
          },
        });
        this.invoice.senders = [];
        this.loadingContacts = false;
        this.allContacts = result.data?.allContactsInDates ?? [];
      } catch (error) {
        this.loadingContacts = false;
        console.log(error);
      }
    },
    calculateInvoice() {
      this.loading = true;
      this.$apollo
        .query({
          query: CALCULATE_INVOICE,
          fetchPolicy: 'network-only',
          variables: {
            start_date: moment(this.invoice.startDate).format('YYYY-MM-DD'),
            end_date: moment(this.invoice.endDate).format('YYYY-MM-DD'),
            senders: this.invoice.senders,
          },
        })
        .then(({ data }) => {
          this.loading = false;
          this.$vs.notify({
            color: 'success',
            title: 'Valor de factura calculado',
            text: 'Revisa el valor de factura calculado.',
          });
          this.calculatedInvoice = data.calculateInvoice.calculatedInvoice;
          this.invoiceWasCalculated = true;
        })
        .catch((err) => {
          this.loading = false;
          this.invoiceWasCalculated = false;
          console.log({ err });
          this.$vs.notify({
            color: 'danger',
            title: 'Valor de factura no calculado',
            text: 'Ocurrió un error al calcular la factura.',
          });
        });
    },
    cleanCalculateInvoice() {
      this.invoiceWasCalculated = false;
      this.calculatedInvoice = 0;
    },
    createInvoice() {
      if (!this._verifyIfInvoiceInputIsValid()) {
        return;
      }

      if (!this.invoice.observation) {
        this.$vs.notify({
          color: 'warning',
          title: 'Observación es obligatoria.',
          text: 'El campo observación es obligatorio.',
        });
        return;
      }

      this.loading = true;
      this.$apollo
        .mutate({
          mutation: CREATE_INVOICE,
          variables: {
            input: {
              start_date: moment(this.invoice.startDate).format('YYYY-MM-DD'),
              end_date: moment(this.invoice.endDate).format('YYYY-MM-DD'),
              senders: this.invoice.senders,
              observation: this.invoice.observation,
            },
          },
        })
        .then(() => {
          this.loading = false;
          this.$vs.notify({
            color: 'success',
            title: 'Factura creada',
            text: 'Se ha registrado correctamente la factura.',
          });
          this.$router.push(`/finanzas/facturacion`);
        })
        .catch((err) => {
          this.loading = false;
          console.log({ err });
          this.$vs.notify({
            color: 'danger',
            title: 'Factura no creada',
            text: 'Ocurrió un error al crear la factura.',
          });
        });
    },
    _verifyIfInvoiceInputIsValid() {
      const startDateYear = this.invoice.startDate.getFullYear();
      const endDateYear = this.invoice.endDate.getFullYear();

      const startDateMonth = this.invoice.startDate.getMonth();
      const endDateMonth = this.invoice.endDate.getMonth();

      if (startDateYear !== endDateYear || startDateMonth !== endDateMonth) {
        this.$vs.notify({
          color: 'warning',
          title: 'Error con las fechas seleccionadas.',
          text: 'La fecha de inicio y fecha de fin deben ser del mismo mes. No se puede facturar ventas de distintos meses.',
        });
        return false;
      }

      if (!this.invoice?.senders || this.invoice.senders.length === 0) {
        this.$vs.notify({
          color: 'warning',
          title: 'Los remitentes son requeridos.',
          text: 'Debe seleccionar al menos un remitente.',
        });
        return false;
      }

      return true;
    },
  },
  computed: {
    disabledDatesTo() {
      return { from: new Date() };
    },
    disabledDatesFrom() {
      return { from: new Date(this.invoice.endDate) };
    },
  },
};
</script>

<style></style>
